import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1080.000000 1080.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,1080.000000) scale(0.100000,-0.100000)">
          <path d="M6642 6060 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M6028 6039 c19 -20 14 -32 -9 -26 -15 4 -19 3 -11 -5 11 -11 52 -2
52 12 0 5 -10 15 -22 21 -19 9 -21 9 -10 -2z"/>
<path d="M6654 5910 c0 -69 1 -97 3 -62 2 34 2 90 0 125 -2 34 -3 6 -3 -63z"/>
<path d="M6334 5992 c8 -13 10 -186 2 -209 -4 -14 -3 -23 3 -23 14 0 34 74 21
78 -6 2 -10 39 -10 83 0 49 -4 79 -11 79 -5 0 -8 -3 -5 -8z"/>
<path d="M6087 5953 c3 -7 9 -13 13 -13 4 0 12 6 18 13 8 9 5 12 -13 12 -14 0
-21 -5 -18 -12z"/>
<path d="M6013 5903 c0 -7 3 -13 7 -13 3 0 14 6 23 13 16 11 15 12 -6 12 -13
0 -24 -6 -24 -12z"/>
<path d="M6392 5870 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M5935 5881 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
<path d="M5970 5870 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M5927 5836 c-4 -10 -1 -13 8 -9 8 3 12 9 9 14 -7 12 -11 11 -17 -5z"/>
<path d="M5846 5814 c-5 -14 -4 -15 9 -4 17 14 19 20 6 20 -5 0 -12 -7 -15
-16z"/>
<path d="M5250 5816 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M5889 5803 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z"/>
<path d="M6392 5785 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M5250 5781 c0 -5 7 -11 14 -14 10 -4 13 -1 9 9 -6 15 -23 19 -23 5z"/>
<path d="M5850 5775 c0 -8 2 -15 4 -15 2 0 6 7 10 15 3 8 1 15 -4 15 -6 0 -10
-7 -10 -15z"/>
<path d="M5760 5750 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M5795 5738 l-20 -21 22 12 c23 12 40 31 26 30 -4 0 -17 -10 -28 -21z"/>
<path d="M6642 5735 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M5182 5710 c-7 -11 -8 -20 -3 -20 9 0 24 30 18 36 -1 2 -8 -6 -15
-16z"/>
<path d="M6303 5658 c-9 -41 -8 -56 1 -66 10 -10 12 1 13 53 0 36 0 65 -1 65
-1 0 -7 -24 -13 -52z"/>
<path d="M5135 5690 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10 -2
0 -7 -4 -10 -10z"/>
<path d="M5062 5621 c-29 -30 -49 -57 -44 -60 5 -3 12 -13 16 -21 3 -8 4 -4 1
10 -5 30 1 40 37 63 24 15 55 56 46 61 -2 1 -27 -23 -56 -53z"/>
<path d="M5684 5668 c9 -5 23 -5 32 0 12 6 7 9 -16 9 -23 0 -28 -3 -16 -9z"/>
<path d="M5629 5653 c-13 -17 -13 -17 6 -3 11 8 22 16 24 17 2 2 0 3 -6 3 -6
0 -17 -8 -24 -17z"/>
<path d="M5603 5617 c4 -10 0 -20 -11 -26 -9 -6 -12 -10 -7 -10 6 0 17 5 26
12 15 11 15 14 1 27 -14 13 -15 12 -9 -3z"/>
<path d="M4416 5595 c4 -16 11 -34 16 -40 7 -7 8 -1 3 15 -12 40 -26 59 -19
25z"/>
<path d="M6561 5584 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6591 5584 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5550 5570 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M6276 5571 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
<path d="M6603 5549 c-7 -33 -7 -33 -15 -9 -7 22 -7 21 -3 -6 2 -17 0 -35 -4
-40 -10 -10 -25 -74 -18 -74 8 0 59 151 53 157 -3 3 -9 -9 -13 -28z"/>
<path d="M4970 5533 c-13 -15 -12 -16 4 -10 10 4 20 7 22 7 2 0 4 5 4 10 0 15
-13 12 -30 -7z"/>
<path d="M4410 5538 c0 -4 5 -18 11 -31 8 -18 8 -30 -1 -46 -10 -20 -10 -21 7
-10 14 8 18 21 16 43 -3 27 -2 28 6 10 5 -12 14 -25 20 -29 6 -3 4 10 -6 29
-12 25 -19 32 -25 24 -6 -10 -10 -10 -18 2 -5 8 -10 12 -10 8z"/>
<path d="M6561 5514 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4121 5490 c15 -45 24 -54 15 -15 -4 17 -12 34 -17 40 -5 5 -4 -6 2
-25z"/>
<path d="M4140 5509 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M5394 5505 c-10 -8 -14 -14 -9 -14 6 0 19 6 29 14 11 8 15 15 10 15
-6 0 -19 -7 -30 -15z"/>
<path d="M5465 5510 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M4402 5490 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M4941 5496 c-8 -10 -8 -16 3 -25 11 -10 14 -7 12 14 -1 29 0 29 -15
11z"/>
<path d="M5387 5460 c-19 -16 -39 -30 -45 -30 -5 0 -16 -7 -23 -17 -13 -15
-12 -15 11 -2 14 8 34 22 45 31 11 9 34 24 50 32 19 10 24 16 13 16 -10 0 -33
-13 -51 -30z"/>
<path d="M4905 5460 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M6220 5444 c-6 -14 -15 -35 -21 -47 -10 -20 -10 -21 5 -7 9 9 20 30
25 48 11 38 5 43 -9 6z"/>
<path d="M4465 5449 l-20 -8 20 0 c13 -1 27 -15 39 -41 11 -22 23 -40 27 -40
5 0 -25 66 -45 96 -1 1 -10 -2 -21 -7z"/>
<path d="M4865 5424 c-11 -17 -12 -25 -4 -22 6 2 16 14 20 26 11 29 5 28 -16
-4z"/>
<path d="M5280 5389 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M6222 5380 c-7 -11 -8 -20 -3 -20 9 0 24 30 18 36 -1 2 -8 -6 -15
-16z"/>
<path d="M6519 5378 c-1 -13 -1 -32 0 -43 2 -20 1 -20 -16 1 -18 20 -18 19
-18 -27 0 -26 3 -45 8 -42 4 2 7 9 7 15 0 5 9 21 20 35 12 15 18 34 15 44 -4
11 1 22 12 28 16 9 16 10 -4 11 -17 0 -23 -6 -24 -22z"/>
<path d="M4830 5375 c0 -8 7 -15 16 -15 14 0 14 3 4 15 -7 8 -14 15 -16 15 -2
0 -4 -7 -4 -15z"/>
<path d="M4180 5353 c0 -3 12 -30 26 -59 23 -48 28 -52 41 -38 9 8 12 18 9 22
-4 3 -11 0 -17 -8 -6 -10 -8 -2 -4 25 6 41 -7 76 -22 61 -4 -5 -2 -11 5 -13 6
-3 12 -17 12 -31 -1 -26 -1 -26 -15 -8 -8 11 -15 28 -15 38 0 10 -4 18 -10 18
-5 0 -10 -3 -10 -7z"/>
<path d="M5129 5351 c9 -6 10 -11 2 -15 -6 -4 -11 -12 -11 -18 0 -6 9 -3 20 7
16 14 17 20 8 27 -7 4 -17 8 -23 8 -5 0 -4 -4 4 -9z"/>
<path d="M5210 5346 c4 -4 16 -4 26 2 16 9 14 10 -8 9 -15 -1 -23 -6 -18 -11z"/>
<path d="M4777 5333 c-11 -10 -8 -20 7 -26 10 -4 12 0 9 14 -6 21 -7 22 -16
12z"/>
<path d="M5180 5330 c0 -5 -7 -10 -15 -10 -8 0 -15 -4 -15 -10 0 -13 11 -12
33 4 19 15 22 26 7 26 -5 0 -10 -4 -10 -10z"/>
<path d="M4561 5314 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6122 5295 c-7 -8 -9 -15 -4 -15 5 0 15 7 22 15 16 19 -1 19 -18 0z"/>
<path d="M4506 5271 c-11 -4 -21 -20 -23 -37 -3 -16 -3 -26 -1 -24 2 3 16 20
32 38 30 32 28 37 -8 23z"/>
<path d="M4986 5261 c-9 -10 -18 -31 -19 -47 -2 -22 -11 -32 -45 -49 -23 -11
-42 -26 -42 -32 0 -9 -18 -13 -58 -13 -66 0 -92 -18 -92 -63 0 -15 -5 -27 -12
-27 -14 0 -78 -42 -78 -52 0 -4 25 7 56 25 39 22 54 37 51 48 -6 22 2 32 36
47 38 16 56 15 33 -3 -16 -12 -17 -15 -4 -15 8 0 21 7 28 15 7 8 18 15 26 15
7 0 17 6 21 13 10 15 89 67 102 67 6 0 19 10 31 22 11 12 20 18 20 12 0 -5 4
-4 8 1 4 6 20 19 35 29 42 26 26 31 -26 7 -28 -13 -47 -29 -51 -43 -5 -18 -8
-20 -15 -9 -13 21 17 64 41 58 10 -3 18 -1 18 4 0 16 -47 9 -64 -10z"/>
<path d="M6137 5258 c-8 -31 -45 -47 -59 -27 -8 11 -9 11 -5 -1 5 -20 51 -25
66 -6 7 8 11 25 9 38 -3 22 -4 22 -11 -4z"/>
<path d="M6095 5260 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M4682 5230 c-13 -16 -19 -30 -14 -30 10 0 52 43 52 53 0 16 -18 5
-38 -23z"/>
<path d="M6429 5238 c-1 -13 -2 -30 -3 -40 -1 -9 -4 -20 -8 -24 -5 -4 -8 -12
-8 -18 0 -6 6 -2 14 9 16 25 28 95 15 95 -5 0 -10 -10 -10 -22z"/>
<path d="M6402 5230 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M6460 5235 c-7 -9 -11 -17 -9 -20 3 -2 10 5 17 15 14 24 10 26 -8 5z"/>
<path d="M6157 5220 c-3 -13 0 -20 9 -20 15 0 19 26 5 34 -5 3 -11 -3 -14 -14z"/>
<path d="M4280 5170 c22 -31 30 -37 30 -22 0 11 -35 52 -44 52 -4 0 2 -13 14
-30z"/>
<path d="M4420 5196 c0 -2 7 -9 15 -16 9 -7 15 -8 15 -2 0 5 -7 12 -15 16 -8
3 -15 4 -15 2z"/>
<path d="M4895 5180 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M6015 5179 c-11 -17 1 -21 15 -4 8 9 8 15 2 15 -6 0 -14 -5 -17 -11z"/>
<path d="M4605 5149 c-16 -16 -24 -29 -17 -29 14 0 52 36 52 50 0 13 -1 13
-35 -21z"/>
<path d="M6050 5164 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
-10 -7 -10 -16z"/>
<path d="M5968 5145 l-26 -23 28 2 c16 1 32 5 37 9 4 5 0 5 -10 1 -9 -3 -17
-2 -17 4 0 6 6 12 13 15 6 2 10 7 7 10 -3 3 -17 -5 -32 -18z"/>
<path d="M4410 5149 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M6028 5133 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4331 5115 c0 -5 4 -17 9 -25 8 -13 13 -12 32 8 12 12 17 22 10 22
-6 0 -12 -4 -12 -10 0 -14 -27 -12 -33 3 -3 6 -6 8 -6 2z"/>
<path d="M6290 5109 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M6325 5110 c-7 -12 3 -50 13 -49 12 1 54 59 43 59 -6 0 -16 -9 -23
-20 -7 -11 -16 -20 -20 -20 -13 0 -9 28 5 33 9 4 9 6 0 6 -6 1 -15 -4 -18 -9z"/>
<path d="M4527 5073 l-26 -28 26 -2 c18 -1 22 2 12 7 -11 7 -10 13 5 29 28 31
14 25 -17 -6z"/>
<path d="M5871 5073 l-33 -28 34 -1 c18 -1 24 1 14 3 -17 5 -16 7 10 29 16 13
24 23 19 24 -6 0 -26 -12 -44 -27z"/>
<path d="M4765 5069 c-4 -6 -5 -13 -2 -16 7 -7 27 6 27 18 0 12 -17 12 -25 -2z"/>
<path d="M6255 5051 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
<path d="M5786 5025 c-16 -12 -17 -15 -4 -15 9 0 18 7 22 15 7 18 6 18 -18 0z"/>
<path d="M4445 4990 c-16 -16 -24 -30 -18 -30 10 0 34 24 47 48 12 20 2 14
-29 -18z"/>
<path d="M4930 4990 c-14 -11 -31 -20 -38 -20 -7 0 -15 -6 -18 -14 -3 -8 -13
-17 -22 -20 -9 -3 -33 -16 -52 -28 -19 -13 -59 -39 -89 -58 -50 -32 -52 -35
-30 -41 20 -6 21 -7 4 -8 -18 -1 -17 -3 5 -15 14 -7 29 -10 34 -8 5 3 3 8 -5
11 -24 10 -16 29 25 66 46 41 54 43 51 13 -1 -13 0 -15 2 -5 2 9 12 17 21 17
13 0 14 2 4 9 -16 9 -1 24 36 35 12 4 22 14 22 22 0 8 8 14 18 14 9 1 31 12
47 25 35 29 22 34 -15 5z"/>
<path d="M5721 4999 c-13 -5 -21 -12 -18 -15 3 -3 18 1 34 10 31 17 21 20 -16
5z"/>
<path d="M4990 4990 c8 -5 20 -10 25 -10 6 0 3 5 -5 10 -8 5 -19 10 -25 10 -5
0 -3 -5 5 -10z"/>
<path d="M6250 4985 c-7 -8 -8 -15 -2 -15 5 0 15 7 22 15 7 8 8 15 2 15 -5 0
-15 -7 -22 -15z"/>
<path d="M5665 4980 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M5755 4980 l-20 -9 20 0 c11 0 29 4 40 9 l20 9 -20 0 c-11 0 -29 -4
-40 -9z"/>
<path d="M4955 4970 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M5050 4970 c8 -5 24 -10 34 -10 26 0 18 6 -19 14 -22 5 -26 4 -15 -4z"/>
<path d="M5628 4963 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5698 4963 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M4545 4950 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M4580 4943 c-14 -10 -37 -25 -51 -33 -59 -34 -129 -83 -125 -88 3 -2
26 10 51 27 25 17 48 31 51 31 3 0 100 65 113 76 14 13 -19 1 -39 -13z"/>
<path d="M6164 4926 c-1 -17 1 -23 3 -13 2 9 9 17 14 17 13 0 11 17 -3 23 -9
3 -13 -6 -14 -27z"/>
<path d="M6207 4947 c3 -5 -3 -18 -13 -30 -16 -19 -14 -18 11 3 17 14 33 27
37 30 4 3 -4 5 -17 5 -12 0 -21 -4 -18 -8z"/>
<path d="M4490 4935 c-19 -23 -8 -26 20 -5 19 15 21 20 9 20 -9 0 -22 -7 -29
-15z"/>
<path d="M5130 4940 c8 -5 26 -10 40 -10 20 0 22 2 10 10 -8 5 -26 10 -40 10
-20 0 -22 -2 -10 -10z"/>
<path d="M5550 4940 c-8 -5 -12 -12 -9 -15 8 -8 39 5 39 16 0 11 -11 11 -30
-1z"/>
<path d="M5208 4933 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5498 4933 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5298 4923 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M5371 4926 c2 -2 31 -6 64 -9 39 -4 50 -3 30 2 -31 9 -102 14 -94 7z"/>
<path d="M4355 4900 c-10 -11 -13 -20 -8 -20 13 0 38 29 31 35 -3 3 -13 -4
-23 -15z"/>
<path d="M5128 4913 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5257 4903 c18 -2 50 -2 70 0 21 2 7 4 -32 4 -38 0 -55 -2 -38 -4z"/>
<path d="M5575 4900 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M5485 4890 c28 -4 54 -3 60 1 5 5 -16 8 -50 8 l-60 -2 50 -7z"/>
<path d="M5138 4883 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5228 4883 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5378 4883 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
<path d="M6106 4869 c2 -4 -9 -15 -24 -24 -27 -18 -67 -55 -58 -55 2 0 35 20
72 45 62 40 66 44 37 42 -17 -1 -29 -5 -27 -8z"/>
<path d="M4400 4860 c0 -5 7 -7 15 -4 8 4 15 8 15 10 0 2 -7 4 -15 4 -8 0 -15
-4 -15 -10z"/>
<path d="M6022 4851 c-12 -10 -22 -27 -21 -37 0 -16 2 -15 9 4 6 13 24 29 42
37 25 11 28 14 12 15 -12 0 -31 -8 -42 -19z"/>
<path d="M4305 4850 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M4745 4824 c-23 -10 -25 -13 -10 -13 21 -1 53 15 45 22 -3 2 -18 -2
-35 -9z"/>
<path d="M4302 4815 c-6 -8 -20 -12 -30 -9 -14 5 -15 4 -2 -5 24 -17 46 -13
47 9 2 23 2 24 -15 5z"/>
<path d="M4780 4803 c-23 -10 -39 -29 -32 -40 10 -15 52 -35 52 -25 0 6 -9 12
-20 15 -11 3 -20 10 -20 17 0 7 9 9 23 6 12 -3 16 -3 9 0 -10 5 -10 9 -1 20
12 15 12 15 -11 7z"/>
<path d="M4336 4785 c-11 -8 -18 -17 -15 -19 2 -3 15 4 29 14 14 11 21 20 15
19 -5 0 -19 -6 -29 -14z"/>
<path d="M5920 4790 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M4225 4770 c-3 -5 -1 -18 4 -29 9 -16 6 -23 -23 -40 -41 -26 -47 -26
-39 -3 3 9 4 15 2 12 -3 -3 -32 -34 -65 -69 -56 -61 -57 -62 -18 -35 23 16 39
33 36 38 -3 5 4 7 15 4 17 -4 20 -2 16 16 -3 14 -2 17 4 9 6 -9 19 -6 51 13
23 14 42 29 42 33 0 5 14 14 30 21 17 7 30 16 30 21 0 9 -28 1 -47 -13 -7 -6
-18 -6 -24 -2 -9 5 -7 11 6 21 16 11 17 13 3 13 -9 0 -20 -4 -23 -10z"/>
<path d="M5948 4764 c-16 -8 -28 -17 -28 -19 0 -11 19 -4 41 15 29 23 24 25
-13 4z"/>
<path d="M4195 4729 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M4845 4710 c27 -12 43 -12 25 0 -8 5 -22 9 -30 9 -10 0 -8 -3 5 -9z"/>
<path d="M4898 4713 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5680 4698 c0 -12 3 -18 7 -14 11 10 12 36 2 36 -5 0 -9 -10 -9 -22z"/>
<path d="M5798 4713 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5850 4710 c-18 -12 -2 -12 25 0 13 6 15 9 5 9 -8 0 -22 -4 -30 -9z"/>
<path d="M4950 4700 c8 -5 26 -10 39 -10 32 0 24 6 -19 14 -25 4 -31 3 -20 -4z"/>
<path d="M4904 4692 c9 -16 84 -35 110 -28 24 6 38 36 16 36 -5 0 -10 -7 -10
-15 0 -19 -46 -19 -80 0 -28 16 -44 19 -36 7z"/>
<path d="M5778 4693 c-10 -2 -18 -8 -18 -13 0 -8 57 4 68 15 6 6 -25 5 -50 -2z"/>
<path d="M5703 4673 c-13 -2 -23 -9 -23 -15 0 -5 7 -7 16 -4 9 3 24 6 35 6 10
0 19 5 19 10 0 10 -8 11 -47 3z"/>
<path d="M5078 4633 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5120 4629 c0 -10 74 -17 80 -8 3 4 23 9 45 11 22 3 3 4 -42 4 -46
-1 -83 -4 -83 -7z"/>
<path d="M5393 4633 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M5618 4633 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5533 4623 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M5243 4613 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M5317 4613 c18 -2 50 -2 70 0 21 2 7 4 -32 4 -38 0 -55 -2 -38 -4z"/>
<path d="M5453 4613 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M4240 4494 c0 -19 4 -25 15 -20 8 3 16 4 17 3 1 -1 4 -75 7 -164 6
-157 5 -162 -14 -165 -12 -2 -21 -12 -23 -25 -3 -20 1 -21 65 -21 62 0 82 8
83 30 0 4 -10 8 -22 10 -23 3 -23 5 -26 183 -2 128 -6 181 -15 187 -6 4 -29 8
-49 8 -34 0 -38 -3 -38 -26z"/>
<path d="M5914 4505 c-7 -18 3 -35 21 -35 11 0 15 -31 17 -160 3 -158 3 -159
-19 -165 -15 -3 -23 -13 -23 -26 0 -18 6 -19 72 -17 59 2 73 6 76 20 2 11 -5
18 -20 20 -23 3 -23 3 -25 180 -1 98 -4 182 -8 188 -10 16 -85 12 -91 -5z"/>
<path d="M4080 4489 c0 -12 5 -19 12 -17 23 8 34 -14 28 -61 -4 -34 -9 -45
-20 -42 -73 20 -129 -1 -156 -57 -25 -52 -22 -132 7 -171 32 -43 75 -56 130
-37 26 9 47 12 54 6 6 -5 25 -8 43 -8 24 1 32 6 32 19 0 10 -8 20 -17 23 -16
5 -18 22 -15 186 l3 180 -51 0 c-44 0 -50 -2 -50 -21z m21 -169 c16 -9 19 -22
19 -73 0 -71 -5 -82 -43 -96 -62 -24 -98 27 -83 117 9 56 56 79 107 52z"/>
<path d="M4900 4491 c0 -10 8 -21 18 -23 16 -5 17 -18 15 -164 -3 -131 -6
-160 -18 -162 -8 -2 -15 -12 -15 -23 0 -17 8 -19 70 -19 63 0 70 2 70 20 0 12
-9 22 -22 25 -21 6 -23 11 -21 77 3 81 13 98 57 98 47 0 56 -15 56 -98 0 -66
-2 -74 -20 -79 -13 -3 -20 -12 -18 -22 3 -12 18 -17 71 -19 62 -3 67 -1 67 17
0 13 -8 23 -22 26 -22 6 -23 10 -20 76 2 39 1 82 -2 95 -14 56 -85 82 -133 49
-30 -21 -36 -7 -34 76 l2 69 -50 0 c-43 0 -51 -3 -51 -19z"/>
<path d="M2833 4474 c-40 -20 -67 -74 -59 -120 7 -37 38 -63 100 -85 97 -35
77 -143 -23 -123 -26 6 -31 11 -31 35 0 24 -4 29 -25 29 -23 0 -25 -4 -25 -45
0 -49 9 -58 73 -69 105 -19 189 76 144 164 -12 23 -87 70 -113 70 -21 0 -46
38 -42 66 6 53 108 61 108 9 0 -18 5 -25 20 -25 17 0 20 7 20 53 0 33 -3 47
-9 38 -6 -10 -10 -10 -13 -1 -6 20 -89 23 -125 4z"/>
<path d="M4454 4465 c-7 -18 3 -35 22 -35 8 0 14 10 14 25 0 27 -27 35 -36 10z"/>
<path d="M5277 4453 c-4 -3 -7 -21 -7 -39 0 -24 -5 -33 -21 -37 -22 -6 -24
-41 -1 -43 6 -1 15 -2 20 -3 4 0 6 -38 4 -84 -4 -97 7 -133 45 -148 38 -14
107 5 99 27 -3 9 -6 18 -6 20 0 2 -15 4 -34 4 -29 0 -34 4 -39 28 -3 15 -3 55
0 89 5 59 7 62 35 68 38 9 34 38 -7 45 -26 4 -30 10 -33 43 -3 31 -7 37 -26
37 -12 0 -26 -3 -29 -7z"/>
<path d="M5470 4420 c0 -33 -3 -40 -20 -40 -15 0 -20 -7 -20 -25 0 -14 3 -24
8 -24 29 5 32 -4 32 -96 0 -88 2 -98 25 -120 26 -27 49 -31 90 -15 34 13 30
45 -5 46 -52 1 -51 -1 -48 96 l3 92 30 1 c23 0 30 5 30 20 0 15 -8 21 -32 23
-31 3 -33 6 -33 43 0 37 -2 39 -30 39 -28 0 -30 -2 -30 -40z m70 -284 c0 -2
-7 -7 -16 -10 -8 -3 -12 -2 -9 4 6 10 25 14 25 6z"/>
<path d="M3040 4359 c0 -12 8 -23 20 -26 19 -5 20 -14 20 -159 0 -149 -1 -154
-21 -154 -15 0 -20 -5 -17 -22 3 -21 9 -23 73 -25 77 -3 101 6 91 32 -4 12
-14 16 -31 13 -27 -6 -35 7 -35 52 0 36 10 42 47 28 25 -9 37 -8 69 8 55 27
76 72 71 153 -3 57 -7 67 -35 92 -36 32 -66 36 -118 14 -31 -13 -36 -13 -41 0
-3 10 -20 15 -49 15 -38 0 -44 -3 -44 -21z m217 -55 c15 -21 17 -92 4 -127
-11 -28 -60 -40 -99 -26 -21 8 -23 14 -20 81 3 64 6 74 26 84 31 15 73 9 89
-12z"/>
<path d="M3440 4363 c-56 -38 -79 -103 -60 -168 24 -80 87 -117 166 -95 51 15
60 24 42 43 -8 10 -24 11 -53 6 -56 -10 -90 7 -90 46 l0 29 80 0 80 1 -2 48
c-5 88 -95 137 -163 90z m94 -49 c19 -19 21 -44 4 -45 -7 -1 -31 -1 -53 -2
-34 0 -40 4 -46 27 -4 14 -4 24 0 22 4 -3 13 -1 21 5 23 14 57 11 74 -7z"/>
<path d="M3715 4362 c-45 -28 -56 -46 -62 -104 -6 -61 14 -119 50 -142 30 -20
103 -31 111 -17 4 6 18 11 32 11 13 0 24 6 24 13 0 26 -20 33 -72 27 -58 -8
-73 0 -84 44 -7 29 -6 30 73 26 78 -2 93 5 93 47 0 91 -88 142 -165 95z m101
-57 c9 -14 15 -28 12 -30 -2 -2 -28 -5 -58 -7 -44 -2 -55 0 -58 13 -9 48 76
67 104 24z"/>
<path d="M4413 4364 c-4 -12 1 -22 15 -32 20 -13 22 -23 22 -98 0 -81 -1 -84
-26 -90 -16 -4 -24 -12 -22 -23 3 -13 17 -16 78 -16 61 0 75 3 78 16 2 11 -6
19 -22 23 -26 6 -26 7 -26 116 0 61 -3 110 -7 111 -5 0 -25 2 -46 5 -29 4 -39
1 -44 -12z"/>
<path d="M4656 4365 c-8 -9 -18 -14 -22 -13 -15 6 -46 -75 -46 -121 0 -98 65
-157 144 -130 46 15 52 10 43 -31 -12 -54 -60 -71 -127 -45 -26 10 -36 -24
-13 -41 25 -18 109 -18 143 0 47 24 54 52 56 205 1 137 1 140 24 146 33 8 25
39 -12 43 -16 2 -39 -2 -51 -8 -17 -9 -28 -9 -43 -1 -30 16 -78 14 -96 -4z
m112 -49 c3 -3 7 -34 8 -68 3 -52 0 -66 -17 -84 -17 -18 -29 -22 -53 -17 -44
8 -56 28 -56 94 0 49 4 60 23 74 20 15 80 15 95 1z"/>
<path d="M5713 4364 c-40 -20 -65 -72 -65 -134 1 -70 22 -105 77 -126 40 -15
51 -15 88 -4 57 18 57 18 50 37 -5 12 -16 14 -58 9 -64 -7 -98 12 -93 52 3 27
4 27 83 27 90 0 94 4 75 75 -19 69 -88 98 -157 64z m95 -47 c7 -6 12 -20 10
-31 -3 -18 -10 -21 -53 -21 -41 0 -50 3 -53 19 -8 42 57 64 96 33z"/>
<path d="M6314 4366 c-50 -22 -74 -65 -74 -131 0 -67 21 -104 74 -129 42 -20
67 -20 112 -1 31 13 34 17 24 35 -7 13 -16 18 -23 13 -7 -4 -21 -8 -32 -9 -11
-1 -33 -4 -50 -6 -16 -3 -23 -3 -14 -1 15 4 13 8 -7 28 -24 24 -32 80 -18 132
13 47 95 48 110 1 7 -22 40 -23 48 -2 4 10 -6 28 -28 50 -37 37 -69 43 -122
20z"/>
<path d="M6564 4360 c-40 -26 -59 -71 -58 -136 0 -62 22 -99 69 -118 73 -31
153 12 175 92 12 46 5 105 -17 139 -29 44 -117 56 -169 23z m104 -40 c18 -17
22 -32 22 -78 0 -73 -18 -101 -62 -99 -27 2 -36 8 -49 37 -24 51 -13 130 21
147 34 17 43 16 68 -7z m69 -92 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19
0 -25z"/>
<path d="M6797 4373 c-13 -13 -7 -32 12 -38 17 -5 20 -17 23 -95 3 -86 2 -89
-20 -95 -15 -4 -22 -12 -20 -23 3 -14 16 -18 71 -20 61 -3 67 -1 67 16 0 12
-9 22 -22 25 -22 6 -23 10 -20 79 4 80 10 93 47 101 44 8 58 -13 63 -96 3 -70
2 -76 -18 -82 -13 -4 -20 -13 -18 -23 3 -13 17 -18 66 -20 56 -3 62 -1 62 16
0 11 -7 22 -15 26 -12 4 -15 23 -15 85 0 86 4 91 61 91 33 0 37 -10 41 -107 3
-58 1 -63 -20 -68 -15 -4 -22 -12 -20 -23 3 -14 16 -18 71 -20 61 -3 67 -1 67
16 0 11 -9 22 -20 25 -18 5 -20 14 -20 97 0 83 -2 93 -25 115 -28 29 -63 32
-111 9 -30 -14 -35 -14 -56 0 -31 22 -80 20 -111 -4 -25 -20 -25 -20 -30 0 -4
16 -14 20 -44 20 -22 0 -43 -3 -46 -7z"/>
<path d="M7437 4373 c-14 -13 -6 -33 16 -38 21 -6 22 -11 22 -95 0 -84 -1 -89
-22 -95 -14 -3 -23 -13 -23 -25 0 -18 7 -20 70 -19 61 1 70 4 70 19 0 10 -8
21 -18 25 -15 4 -19 17 -21 68 -2 75 16 105 67 111 38 4 40 -2 45 -106 2 -58
0 -68 -16 -72 -12 -3 -17 -12 -15 -23 2 -14 14 -19 57 -21 67 -4 81 1 74 27
-3 11 -8 20 -12 19 -28 -3 -31 5 -31 96 0 90 -1 96 -26 115 -32 25 -81 27
-113 5 -22 -16 -24 -16 -33 0 -9 16 -77 23 -91 9z m253 -59 c0 -8 -5 -12 -10
-9 -6 4 -8 11 -5 16 9 14 15 11 15 -7z"/>
<path d="M7834 4361 c-54 -33 -73 -130 -41 -204 21 -49 71 -72 124 -58 43 12
53 8 53 -23 0 -41 -32 -59 -96 -54 -43 4 -57 1 -62 -10 -14 -38 85 -56 153
-28 51 22 58 45 64 206 5 108 9 144 19 143 7 -2 12 7 12 22 0 22 -4 25 -33 25
-18 0 -38 -5 -45 -12 -9 -9 -18 -9 -37 0 -37 17 -76 15 -111 -7z m87 -32 c19
-7 36 -14 38 -15 2 -2 6 -31 8 -65 4 -52 1 -64 -17 -84 -26 -27 -77 -26 -98 2
-19 24 -9 133 12 149 9 6 13 14 9 17 -10 10 10 8 48 -4z"/>
<path d="M6117 4155 c-22 -16 -19 -44 7 -59 14 -8 20 -8 23 2 3 6 8 10 13 7
14 -9 22 35 10 50 -15 18 -30 18 -53 0z"/>
<path d="M7312 4158 c-20 -20 -14 -55 9 -61 28 -7 46 0 52 22 9 37 -35 65 -61
39z"/>
<path d="M4677 3933 c-44 -18 -61 -56 -55 -125 6 -60 33 -88 88 -88 52 0 62
11 58 60 -3 41 -5 44 -35 47 -20 3 -33 0 -33 -7 0 -6 3 -9 8 -7 18 11 45 -11
47 -37 1 -36 -28 -52 -67 -37 -48 18 -68 95 -40 148 12 22 74 49 91 39 5 -4
12 -2 15 3 10 16 -40 18 -77 4z"/>
<path d="M2520 3931 c0 -6 15 -11 33 -13 l32 -3 0 -98 c0 -58 4 -97 10 -97 6
0 10 39 10 98 l0 97 33 3 c62 6 31 22 -43 22 -41 0 -75 -4 -75 -9z"/>
<path d="M2722 3830 c1 -60 5 -110 10 -110 4 0 8 23 8 50 0 37 4 50 15 50 15
0 65 -49 65 -63 0 -10 26 -37 35 -37 3 0 -6 23 -22 50 -22 39 -25 52 -15 59 7
4 15 25 19 46 8 47 -12 65 -73 65 l-43 0 1 -110z m72 90 c20 -7 26 -17 26 -39
0 -34 -25 -53 -58 -44 -18 4 -22 13 -22 43 0 21 6 41 13 43 6 3 13 6 14 6 1 1
13 -3 27 -9z"/>
<path d="M2954 3923 c-6 -16 -58 -157 -71 -190 -11 -29 14 -11 28 19 12 29 19
32 57 33 39 1 43 -1 54 -32 7 -18 16 -33 20 -33 10 0 0 44 -18 78 -7 14 -21
51 -31 83 -19 61 -29 72 -39 42z m40 -96 c6 -27 5 -28 -26 -25 -36 3 -38 16
-15 70 l12 28 11 -23 c6 -12 14 -35 18 -50z"/>
<path d="M3090 3832 c0 -62 3 -112 8 -112 4 0 6 35 5 77 -1 42 3 86 9 96 7 15
6 23 -6 35 -15 15 -16 7 -16 -96z"/>
<path d="M3232 3863 c-1 -46 -6 -83 -10 -83 -4 0 -27 27 -51 61 -23 33 -45 58
-49 55 -6 -6 107 -176 117 -176 3 0 5 35 3 77 -4 127 -9 154 -10 66z"/>
<path d="M3316 3924 c-34 -33 -15 -76 46 -102 42 -17 53 -47 27 -73 -17 -17
-28 -19 -61 -14 -29 5 -39 4 -34 -4 4 -6 27 -11 51 -11 77 0 106 58 51 102
-11 10 -26 18 -33 18 -6 0 -21 12 -33 27 l-21 28 22 18 c17 13 32 17 56 12 20
-4 33 -2 33 5 0 16 -87 12 -104 -6z"/>
<path d="M3479 3913 c-5 -88 -3 -193 4 -193 7 0 11 54 9 150 l0 55 40 -3 c22
-3 45 1 51 7 8 8 -4 11 -46 11 -55 0 -57 -1 -58 -27z"/>
<path d="M3673 3925 c-48 -33 -54 -136 -10 -182 29 -33 86 -31 118 3 20 21 24
36 24 86 0 53 -3 63 -28 84 -32 27 -73 31 -104 9z m102 -34 c4 -9 9 -36 12
-61 4 -39 0 -50 -21 -73 -30 -32 -54 -34 -83 -7 -29 26 -36 54 -28 107 5 38
11 47 38 58 35 14 71 4 82 -24z"/>
<path d="M3856 3924 c-4 -9 -6 -59 -5 -110 1 -69 4 -94 14 -94 9 0 12 14 10
45 -5 80 31 75 81 -12 10 -18 24 -33 32 -33 8 0 2 16 -18 44 -16 25 -30 47
-30 51 0 3 9 15 20 27 43 46 13 98 -57 98 -29 0 -43 -5 -47 -16z m90 -8 c32
-32 10 -76 -38 -76 -27 0 -28 2 -28 45 0 42 2 45 26 45 14 0 32 -6 40 -14z"/>
<path d="M4059 3853 c-1 -49 -3 -98 -4 -110 -1 -13 3 -23 9 -23 9 0 11 23 8
80 -2 44 0 80 5 80 5 0 22 -22 38 -50 32 -54 42 -60 52 -32 11 29 51 83 55 75
2 -5 3 -41 3 -80 -1 -51 2 -73 11 -73 8 0 12 32 13 110 1 61 -2 110 -6 110 -9
0 -25 -23 -63 -93 l-27 -47 -31 50 c-18 28 -32 56 -32 61 0 5 -5 9 -11 9 -5 0
-7 5 -4 10 3 6 1 10 -4 10 -7 0 -11 -33 -12 -87z"/>
<path d="M4321 3830 c1 -60 5 -110 9 -110 12 0 15 37 12 133 -5 128 -23 109
-21 -23z"/>
<path d="M4405 3830 c0 -81 3 -110 13 -110 8 0 12 23 12 80 0 44 3 80 8 80 4
-1 32 -36 62 -80 50 -73 70 -93 70 -72 0 4 -5 13 -12 20 -7 7 -8 12 -2 12 5 0
10 38 10 90 0 60 -4 90 -12 90 -7 0 -11 -26 -10 -76 1 -42 -2 -78 -5 -80 -3
-2 -32 33 -63 76 -31 44 -60 80 -64 80 -4 0 -7 -49 -7 -110z"/>
<path d="M4900 3931 c0 -6 14 -11 31 -13 l31 -3 0 -97 c0 -55 3 -98 9 -98 5 0
9 8 10 18 0 9 1 53 2 97 l2 80 33 3 c62 6 31 22 -43 22 -41 0 -75 -4 -75 -9z"/>
<path d="M5100 3830 c0 -60 4 -110 8 -110 5 0 9 17 10 38 1 20 4 50 8 66 3 16
2 26 -2 23 -5 -3 -8 17 -7 44 1 27 -3 49 -8 49 -5 0 -9 -48 -9 -110z"/>
<path d="M5227 3883 c-2 -32 -2 -62 1 -68 3 -5 5 -28 3 -52 -1 -24 2 -43 7
-43 15 0 18 19 16 110 -3 111 -3 110 -13 110 -5 0 -11 -26 -14 -57z"/>
<path d="M5315 3930 c-3 -5 -5 -55 -4 -110 l3 -100 65 0 c48 0 62 3 51 10 -8
6 -29 9 -45 8 -39 -3 -54 7 -48 32 3 11 0 26 -6 34 -14 17 5 26 55 26 19 0 34
4 34 9 0 6 -18 8 -40 5 -22 -3 -40 -1 -41 3 -8 63 -5 68 39 71 23 2 42 7 42
13 0 12 -97 12 -105 -1z"/>
<path d="M5580 3834 c35 -129 42 -140 54 -84 3 14 14 52 24 85 l19 60 12 -30
c7 -16 15 -43 18 -60 8 -48 25 -87 34 -78 5 5 17 42 28 83 30 118 32 130 24
130 -5 0 -17 -30 -27 -67 -10 -38 -21 -78 -25 -91 -5 -17 -12 -3 -30 63 -13
47 -26 85 -30 85 -8 0 -24 -43 -41 -110 -11 -41 -13 -43 -26 -25 -8 10 -14 31
-14 46 0 15 -4 31 -10 34 -5 3 -10 19 -10 36 0 18 -5 29 -14 29 -12 0 -9 -21
14 -106z"/>
<path d="M5871 3917 c-6 -16 -11 -31 -11 -34 0 -3 -6 -19 -14 -36 -17 -40 -46
-116 -46 -122 0 -3 4 -5 9 -5 4 0 12 15 17 33 9 30 12 32 54 31 41 0 46 -3 59
-32 8 -18 18 -32 21 -32 8 0 -28 111 -58 180 l-20 45 -11 -28z m27 -55 c2 -12
7 -20 11 -18 4 3 7 -5 6 -17 -1 -17 -9 -23 -33 -25 -36 -4 -36 -4 -20 50 12
41 31 46 36 10z"/>
<path d="M5983 3913 c7 -16 21 -41 31 -56 11 -16 21 -53 24 -83 6 -67 18 -71
19 -6 1 34 10 64 36 110 20 34 32 62 28 62 -11 0 -36 -38 -46 -70 -17 -53 -30
-51 -55 11 -12 33 -29 59 -36 59 -10 0 -11 -5 -1 -27z"/>
<path d="M6245 3932 c6 -4 25 -35 43 -68 22 -43 31 -73 29 -99 -1 -21 3 -40 8
-42 6 -1 11 19 13 47 2 34 13 67 33 101 30 51 35 69 21 69 -4 0 -21 -28 -37
-62 -22 -47 -31 -57 -35 -42 -10 37 -55 104 -70 104 -9 0 -11 -3 -5 -8z"/>
<path d="M6463 3925 c-48 -34 -48 -157 0 -190 22 -16 64 -19 92 -9 58 23 58
185 0 208 -29 11 -70 7 -92 -9z m113 -55 c12 -37 1 -109 -19 -122 -49 -33 -88
-13 -105 53 -15 61 1 106 41 118 41 11 68 -5 83 -49z"/>
<path d="M6658 3843 c2 -78 6 -101 19 -110 26 -20 80 -16 103 7 18 18 21 32
19 110 -2 114 -19 118 -19 5 0 -72 -3 -88 -21 -106 -14 -14 -27 -18 -42 -13
-43 13 -47 23 -47 115 0 49 -3 89 -7 89 -5 0 -7 -44 -5 -97z"/>
<path d="M6987 3920 c-77 -61 -39 -200 54 -200 21 0 40 4 43 9 3 5 -13 8 -35
7 -55 -1 -79 28 -79 96 0 41 5 53 27 74 21 19 35 24 55 20 16 -3 28 -1 28 4 0
18 -67 10 -93 -10z"/>
<path d="M7151 3922 c-12 -9 -23 -24 -25 -32 -1 -8 -6 -26 -10 -38 -10 -30 18
-108 44 -122 54 -29 109 2 127 70 26 96 -66 179 -136 122z m89 -10 c18 -14 24
-32 28 -75 4 -50 2 -58 -21 -81 -54 -53 -107 -13 -107 80 0 81 48 118 100 76z"/>
<path d="M7340 3830 c0 -127 16 -151 19 -28 1 45 3 83 4 85 2 1 30 -36 62 -82
33 -47 63 -85 66 -85 8 0 11 177 4 200 -14 44 -25 16 -25 -62 l0 -83 -20 25
c-11 14 -37 51 -57 82 -21 31 -41 57 -45 58 -5 0 -8 -49 -8 -110z"/>
<path d="M7552 3830 c1 -70 6 -110 12 -110 7 0 10 30 8 85 -2 47 0 85 5 85 4
-1 17 -15 29 -33 47 -72 86 -127 95 -132 5 -4 9 37 9 104 0 74 -4 111 -11 111
-7 0 -10 -23 -9 -62 3 -89 0 -127 -8 -107 -12 26 -116 169 -125 169 -4 0 -6
-49 -5 -110z"/>
<path d="M7770 3900 c0 -22 5 -40 10 -40 6 0 10 15 10 33 l0 32 51 -4 c36 -2
49 0 45 8 -4 6 -32 11 -62 11 l-54 0 0 -40z"/>
<path d="M7963 3916 c-24 -21 -27 -31 -28 -86 0 -55 3 -64 28 -86 30 -26 81
-32 107 -13 11 8 6 9 -22 5 -55 -7 -98 30 -98 84 0 74 41 118 96 104 14 -4 24
-2 24 5 0 22 -77 12 -107 -13z"/>
<path d="M8080 3931 c0 -6 17 -11 38 -13 l37 -3 -2 -97 c-1 -76 2 -98 12 -98
11 0 13 22 13 98 l-1 97 37 3 c20 2 34 7 31 13 -8 12 -165 12 -165 0z"/>
<path d="M7772 3788 c2 -32 -1 -60 -7 -62 -5 -3 21 -5 58 -5 38 -1 66 3 63 8
-3 5 -26 6 -51 3 l-46 -5 3 49 c3 49 3 49 41 52 56 5 45 21 -14 20 l-51 -1 4
-59z"/>
<path d="M3513 3834 c11 -11 67 -14 67 -4 0 6 -16 10 -37 10 -20 0 -34 -3 -30
-6z"/>
<path d="M5140 3831 c0 -11 56 -9 66 2 4 4 -9 7 -29 7 -20 0 -37 -4 -37 -9z"/>
<path d="M8256 3742 c-3 -6 -1 -14 5 -17 15 -10 25 3 12 16 -7 7 -13 7 -17 1z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
